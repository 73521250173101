import React, {Component} from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";

import {Colors, FontStyles} from "@flow/style";

import {formatDateTime} from "../utils/dateFormatter";
import FileItem from "./FileItem";

class Summary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            variables: undefined,
            variablesMessage: ""
            
        };
        // fetch variables from the Camunda API.
        if (props.caseData._meta.processInstanceId) {
            fetch('http://localhost:8080/engine-rest/history/variable-instance?processInstanceId=' + props.caseData._meta.processInstanceId
            )
                .then(response => response.json())
                .then(data => {
                    this.setState({variables: data})
                })
                .catch
                (error => {
                    this.setState({variables: undefined, variablesMessage: error.message});
                });
        }
    }
    
    render() {
        // return (<div>{util.inspect(this.props)}</div>);
        const caseData = this.props.caseData;
        if (!caseData.data) {
            return (<div/>);
        }
        switch (caseData.flowDefinitionId) {
            case "company-customer-declaration": {
                return this.renderDeclarationCase();
            }
            case "corporate-periodic-renewal":
            case "corporate-periodic-renewal2":
                return this.renderPeriodicControlCase();
            case "cleanup-files-and-flows": {
                return this.renderCleanupCase();
            }
        }
    }
    
    /**Display the process variables if the Camunda API is available at localhost:8080*/
    renderVariables() {
        if (this.state.variables) {
            return (<>
                    <ListTitle>Variables</ListTitle>
                    <List>
                        {this.state.variables.map(v => (<li key={v.name}>{v.name} = {JSON.stringify(v.value)}</li>))}
                    </List>
                </>
            );
        } else
            // return (this.state.variablesMessage);
            return (
                <div>
                    <p>Error fetching variables: {this.state.variablesMessage}</p>
                    <div>To display the process variables:
                        <ol>
                            <li>Bypass CORS protection by installing and enabling Chrome extension
                                <a href="https://chrome.google.com/webstore/detail/allow-cors-access-control/lhobafahddgcelffkeicbaginigeejlf">Allow
                                    CORS</a>
                            </li>
                            <li>
                                Proxy Camunda API via
                                <pre>
                                stacc forward camunda
                            </pre>
                            </li>
                        </ol>
                    </div>
                </div>
            );
    }

    renderCleanupCase() {
        const caseData = this.props.caseData;
        const created = caseData.createdAt
            ? formatDateTime(new Date(caseData.createdAt))
            : false;
        return (<div>
                <CaseSummary>
                    <EntryFrame>
                        <Title style={{color: "#bbb", fontSize: 12, marginBottom: 8}}>
                            flowId
                        </Title>
                        <p>{caseData.flowId}</p>
                    </EntryFrame>
                    <EntryFrame>
                        <Title
                            style={{color: "#bbb", fontSize: 12, marginBottom: 8}}
                        >
                            {"Opprettet"}
                        </Title>
                        <p
                            style={{
                                fontSize: 18,
                                fontWeight: 500,
                                marginBottom: 8
                            }}
                        >
                            {created}
                        </p>
                    </EntryFrame>
                </CaseSummary>
                {this.renderVariables()}
            </div>
        )
    }
    
    renderPeriodicControlCase() {
        const caseData = this.props.caseData;
        const created = caseData.createdAt
            ? formatDateTime(new Date(caseData.createdAt))
            : false;
        return (<div>
                <CaseSummary>
                    <EntryFrame>
                        <Title style={{color: "#bbb", fontSize: 12, marginBottom: 8}}>
                            flowId
                        </Title>
                        <p>{caseData.flowId}</p>
                    </EntryFrame>
                    <EntryFrame>
                        <Title
                            style={{color: "#bbb", fontSize: 12, marginBottom: 8}}
                        >
                            {"Opprettet"}
                        </Title>
                        <p
                            style={{
                                fontSize: 18,
                                fontWeight: 500,
                                marginBottom: 8
                            }}
                        >
                            {created}
                        </p>
                    </EntryFrame>
                </CaseSummary>
                {this.renderVariables()}
            </div>
        )
    }
    
    renderDeclarationCase() {
        const caseData = this.props.caseData;
        const t = this.props.t;
        const referenceID = caseData.referenceId ? caseData.referenceId : false;
        const flowDefinitionId = caseData.flowDefinitionId
            ? caseData.flowDefinitionId
            : false;
        
        const company = caseData.data.company.name.value
            ? caseData.data.company.name.value
            : false;
        const organizationNumber = caseData.data.company.organizationNumber.value
            ? caseData.data.company.organizationNumber.value
            : false;
        const companyRegisteredDate = caseData.data.company.organizationNumber
            .registeredDate
            ? caseData.data.company.organizationNumber.registeredDate
            : false;
        const bank = caseData.data.bank.orgId ? caseData.data.bank.orgId : false;
        const updated = caseData.updatedAt
            ? formatDateTime(new Date(caseData.updatedAt))
            : false;
        const created = caseData.createdAt
            ? formatDateTime(new Date(caseData.createdAt))
            : false;
        
        const taskItems = [];
        
        const attachments =
            caseData.data.declarationForm &&
            caseData.data.declarationForm.attachments
                ? caseData.data.declarationForm.attachments
                : false;
        
        const file = caseData.data.documentId ? caseData.data.documentId : false;
        
        if (!!attachments) {
            attachments.map(task => {
                return taskItems.push(
                    <li>
                        <FileItem {...task} type={"attachment"}/>
                    </li>
                );
            });
        }
        
        if (!!file) {
            taskItems.push(
                <li>
                    <FileItem {...caseData.data} type={"file"}/>
                </li>
            );
        }
        
        return (
            <>
                <CaseSummary>
                    <EntryFrame>
                        <Title
                            style={{color: "#bbb", fontSize: 12, marginBottom: 8}}
                        >
                            {"Opprettet"}
                        </Title>
                        <p
                            style={{
                                fontSize: 18,
                                fontWeight: 500,
                                marginBottom: 8
                            }}
                        >
                            {created}
                        </p>
                    </EntryFrame>
                    <EntryFrame>
                        <Title
                            style={{color: "#bbb", fontSize: 12, marginBottom: 8}}
                        >
                            {"Sist oppdatert"}
                        </Title>
                        <p
                            style={{
                                fontSize: 18,
                                fontWeight: 500,
                                marginBottom: 8
                            }}
                        >
                            {updated}
                        </p>
                    </EntryFrame>
                    <EntryFrame>
                        <Title
                            style={{color: "#bbb", fontSize: 12, marginBottom: 8}}
                        >
                            {"Prosess"}
                        </Title>
                        <p
                            style={{
                                fontSize: 18,
                                fontWeight: 500,
                                marginBottom: 8
                            }}
                        >
                            {t(flowDefinitionId)}
                        </p>
                    </EntryFrame>
                    <EntryFrame>
                        <Title
                            style={{color: "#bbb", fontSize: 12, marginBottom: 8}}
                        >
                            {"Referanse"}
                        </Title>
                        <p
                            style={{
                                fontSize: 18,
                                fontWeight: 500,
                                marginBottom: 8
                            }}
                        >
                            {`${referenceID}`}
                        </p>
                    </EntryFrame>
                </CaseSummary>
                
                <CaseSummary>
                    <EntryFrame>
                        <Title
                            style={{color: "#bbb", fontSize: 12, marginBottom: 8}}
                        >
                            {"Bank"}
                        </Title>
                        <p
                            style={{
                                fontSize: 18,
                                fontWeight: 500,
                                marginBottom: 8
                            }}
                        >
                            {`${bank}`}
                        </p>
                    </EntryFrame>
                    <Box2>
                        <Title
                            style={{color: "#bbb", fontSize: 12, marginBottom: 8}}
                        >
                            {"Selskap"}
                        </Title>
                        <p
                            style={{
                                fontSize: 18,
                                fontWeight: 500,
                                marginBottom: 8
                            }}
                        >
                            {`${company}`}
                        </p>
                    </Box2>
                    <EntryFrame>
                        <Title
                            style={{color: "#bbb", fontSize: 12, marginBottom: 8}}
                        >
                            {"Selskap OrgNr"}
                        </Title>
                        <p
                            style={{
                                fontSize: 18,
                                fontWeight: 500,
                                marginBottom: 8
                            }}
                        >
                            {`${organizationNumber}`}
                        </p>
                    </EntryFrame>
                    <EntryFrame>
                        <Title
                            style={{color: "#bbb", fontSize: 12, marginBottom: 8}}
                        >
                            {"Bank"}
                        </Title>
                        <p
                            style={{
                                fontSize: 18,
                                fontWeight: 500,
                                marginBottom: 8
                            }}
                        >
                            {`${bank}`}
                        </p>
                    </EntryFrame>
                    <EntryFrame>
                        <Title
                            style={{color: "#bbb", fontSize: 12, marginBottom: 8}}
                        >
                            {"Company Registered"}
                        </Title>
                        <p
                            style={{
                                fontSize: 18,
                                fontWeight: 500,
                                marginBottom: 8
                            }}
                        >
                            {`${companyRegisteredDate}`}
                        </p>
                    </EntryFrame>
                </CaseSummary>
                {taskItems.length > 0 && (
                    <div
                        style={{
                            marginBottom: "30px"
                        }}
                    >
                        <ListTitle>{"Filer"}</ListTitle>
                        <List>{taskItems}</List>
                    </div>
                )}
            </>
        );
    }
}


const List = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    li {
        border-bottom: 1px solid ${Colors.Grey3};
    }

    li:first-of-type {
        border-top: 1px solid ${Colors.Grey3};
    }
`;

const ListTitle = styled.p`
    ${FontStyles.Small};
    margin: 0;
    padding-left: 15px;
    padding-bottom: 15px;
`;

const CaseSummary = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-columns: minmax(200px, auto);
    grid-auto-rows: minmax(20px, auto);
    width: 100%;
    min-width: 550px;
    min-height: 105px;
    justify-items: start;
    padding: 10px;
    margin: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: #f7fbff;
    border: 1px solid #e6e4e3;
    border-radius: 4px;
`;

const TaskVariables = styled.div`
    position: relative;
    width: 100%;
    min-width: 550px;
    min-height: 105px;
    justify-items: start;
    padding: 10px;
    margin: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: #f7fbff;
    border: 1px solid #e6e4e3;
    border-radius: 4px;
`;

const EntryFrame = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    margin-top: 30px;
    margin-bottom: 0px;
`;

const Box2 = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    margin-top: 30px;
    margin-bottom: 0px;
    grid-column-start: 1;
    grid-column-end: 5;
`;

const Title = styled.p`
    ::first-letter {
        text-transform: capitalize;
    }
`;

Summary.propTypes = {
    t: PropTypes.func.isRequired,
    caseData: PropTypes.shape({
        data: PropTypes.shape({})
    }).isRequired
};

export default withTranslation("inbox")(Summary);
