import React from "react";
import styled from "styled-components";
import {withTranslation} from "react-i18next";

import {Colors, FontStyles} from "@flow/style";
import util from "util";

export class CaseDetails extends React.Component {
    render() {return(
        <div>
            {/*<div>{util.inspect(this.props)}</div>*/}
            <pre>{JSON.stringify(this.props.data, undefined, 2)}</pre>
        </div>
    );
    }
}

const List = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    li {
        border-bottom: 1px solid ${Colors.Grey3};
    }
    li:first-of-type {
        border-top: 1px solid ${Colors.Grey3};
    }
`;

const ListTitle = styled.p`
    ${FontStyles.Small};
    margin: 0;
    padding-left: 15px;
    padding-bottom: 15px;
`;

const CaseSummary = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-columns: minmax(200px, auto);
    grid-auto-rows: minmax(20px, auto);
    width: 100%;
    min-width: 550px;
    min-height: 105px;
    justify-items: start;
    padding: 10px;
    margin: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: #f7fbff;
    border: 1px solid #e6e4e3;
    border-radius: 4px;
`;
const EntryFrame = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    margin-top: 30px;
    margin-bottom: 0px;
`;

const Box2 = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    margin-top: 30px;
    margin-bottom: 0px;
    grid-column-start: 1;
    grid-column-end: 5;
`;

const Title = styled.p`
    ::first-letter {
        text-transform: capitalize;
    }
`;


export const CaseDetailsTranslated= withTranslation("inbox")(CaseDetails);
