import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {NavLink} from "react-router-dom";
import {Colors, FontStyles} from "@flow/style";
import {formatDateTime} from "../utils/dateFormatter";

const borderSize = "3px";

const ItemNavLink = styled(NavLink)`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    border-bottom: 1px solid ${Colors.Grey3};
    ${FontStyles.Small};

    &.active {
        pointer-events: none;
    }
`;

const Container = styled.div`
    padding: 10px;
    border-left: ${borderSize} solid transparent;
    background-color: ${props =>
    props.pendingAction || props.pendingChat
        ? Colors.OrangeLightest
        : Colors.Grey4};

    a.active > & {
        background-color: ${Colors.SeaLighter};
        border-left: ${borderSize} solid ${Colors.SeaDark};
    }
`;

const Heading = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Body = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const Title = styled.p`
    ${FontStyles.LargeStrong};
`;

const cutOffAddElipsis = (string, numChars) => {
    if (string.length >= numChars) {
        return string.substr(0, numChars) + "...";
    }
    return string;
};

const QueueItem = ({path, flow, hasPendingChat, t}) => {
    const {flowDefinitionId, flowId, referenceId, flowNumber, data} = flow;
    
    const title = referenceId || flowNumber;
    const createdAtDate = formatDateTime(new Date(flow.createdAt))
        .split(",")[0];
    
    switch (flowDefinitionId) {
        case "corporate-periodic-renewal": 
        case "corporate-periodic-renewal2": {
            const title = referenceId || flowNumber;
            
            return (
                <ItemNavLink
                    activeClassName="active"
                    to={`/inbox/${path}/${flowDefinitionId}/${flowId}`}
                >
                    <Container>
                        <Heading>
                            <p>
                                {title}
                            </p>
                            <p>{createdAtDate}</p>
                        </Heading>
                        <Body>
                            <div style={{width: "100%"}}>
                                {/*<Title>{referenceId}</Title>*/}
                                <p>{flow.status}</p>
                                <span style={{display: "flex"}}>
                                <p>{t(flowDefinitionId)}</p>
                                <p style={{flexGrow:1}}></p>
                                <p style={{color: "red"}}>{flow.hasIncidents?"has incidents":""}</p>
                            </span>
                            </div>
                        </Body>
                    </Container>
                </ItemNavLink>
            );
        }
        case "cleanup-files-and-flows": {
            return (
                <ItemNavLink
                    activeClassName="active"
                    to={`/inbox/${path}/${flowDefinitionId}/${flowId}`}
                >
                    <Container>
                        <Body>
                            <div style={{width: "100%"}}>
                                <Title>{referenceId}</Title>
                                <span style={{display: "flex"}}>
                                <p>{t(flowDefinitionId)}</p>
                            </span>
                            </div>
                        </Body>
                    </Container>
                </ItemNavLink>
            );
        }
        case "company-customer-declaration": {
            
            
            const pendingAction = data.hasPendingDocument;
            const company = cutOffAddElipsis(data.company.name.value, 90);
            const companyOrgNr = data.company.organizationNumber.value;
            
            const orgId = data.bank.orgId;
            
            
            return (
                <ItemNavLink
                    activeClassName="active"
                    to={`/inbox/${path}/${flowDefinitionId}/${flowId}`}
                >
                    <Container
                        pendingAction={pendingAction}
                        pendingChat={hasPendingChat}
                    >
                        <Heading>
                            <p>
                                {companyOrgNr} / {company}
                            </p>
                            <p>{createdAtDate}</p>
                        </Heading>
                        <Body>
                            <div style={{width: "100%"}}>
                                <Title>{title}</Title>
                                <span style={{display: "flex"}}>
                            <p>{t(flowDefinitionId)}</p>
                            <p style={{flexGrow: 1, textAlign: "right"}}>
                                {orgId}
                            </p>
                        </span>
                            </div>
                        </Body>
                    </Container>
                </ItemNavLink>
            );
        }
    }
};

QueueItem.propTypes = {
    path: PropTypes.string.isRequired,
    flow: PropTypes.shape({}),
    hasPendingChat: PropTypes.bool,
    t: PropTypes.func.isRequired
};

QueueItem.defaultProps = {
    flow: {},
    hasPendingChat: false
};

export default QueueItem;
