import React from "react";
import ReactDOM from "react-dom";
import AuthenticationIcon from "./customer/plugins/AuthenticationIcon";
import { CaseManager, APPS } from "@flow/case-manager";
import * as customerSetup from "./customer";
import * as serviceWorker from "./serviceWorker";
import inboxTranslates from "./translates/inbox.json";
import searchTranslates from "./translates/search.json";
import "./index.css";

// I don't see where it is used and I have erros while building with this libs
// require("babel-core/register");
// require("babel-polyfill");

const customTranslates = [
    {
        app: APPS.Inbox,
        language: "no",
        translates: inboxTranslates,
    },
    {
        app: APPS.Search,
        language: "no",
        translates: searchTranslates,
    },
];

const skin = {
    branding: customerSetup.Branding,
    mainLogo: customerSetup.MainLogo,
};

ReactDOM.render(
    <CaseManager
        configuration={(user) => {
            return {
                skin,
                homeUrl: "/inbox",
                apps: [APPS.Inbox, APPS.Search, APPS.Process],
                customComponents: customerSetup.CustomComponents,
                inboxConfiguration: {
                    searchFields: customerSetup.SearchFields,
                    startableProcesses: customerSetup.StartableProcesses,
                    features: [],
                },
                processConfiguration: {
                    errorListDetail: "detailed",
                },
                searchConfiguration: {
                    searchFields: [
                        {
                            name: "Organisasjonsnummer (Selskap - Kundeerklæringer)",
                            value: "data.company.organizationNumber.value",
                            process: ["company-customer-declaration"],
                        },
                        {
                            name: "Organisasjonsnummer (Periodisk Fornyelse)",
                            value: "data.companyOrgNumber",
                            process: ["corporate-periodic-renewal"],
                        },
                        {
                            name: "Selskapsnavn",
                            value: "data.company.name.value",
                            process: ["company-customer-declaration"],
                        },
                        {
                            name: "Selskapsnavn (Periodisk Fornyelse)",
                            value: "data.customer.customerInformation.name",
                            process: ["corporate-periodic-renewal"],
                        },
                        {
                            name: "Bank OrgId",
                            value: "data.bank.orgId",
                            process: [
                                "company-customer-declaration",
                                "corporate-periodic-renewal",
                            ],
                        },
                        {
                            name: "PersonNr (PID)",
                            value: "data.user.pid",
                            process: ["company-customer-declaration"],
                        },
                    ],
                    searchResultFields: [
                        {
                            name: "Selskapsnavn",
                            value: "data.company.name.value",
                            process: ["company-customer-declaration"],
                        },
                        {
                            name: "Selskapsnavn (Periodisk Fornyelse)",
                            value: "data.customer.customerInformation.name",
                            process: ["corporate-periodic-renewal"],
                        },
                        {
                            name: "Organisasjonsnummer (Selskap - Kundeerklæringer)",
                            value: "data.company.organizationNumber.value",
                            process: ["company-customer-declaration"],
                        },
                        {
                            name: "Organisasjonsnummer (Periodisk Fornyelse)",
                            value: "data.companyOrgNumber",
                            process: ["corporate-periodic-renewal"],
                        },
                        {
                            name: "Bank OrgId",
                            value: "data.bank.orgId",
                            mapping: (value) => value, //otherwise, it is converted to the date format, e.g. 01.01.9057
                        },
                        {
                            name: "PersonNr",
                            value: "data.user.pid",
                            process: ["company-customer-declaration"],
                        },
                        {
                            name: "Kunde",
                            value: "data.customer.isCustomer",
                            mapping: (value) =>
                                !!value === true ? "Ja" : "Nei",
                        },
                    ],
                },
                loginConfiguration: {
                    authenticationIcon: AuthenticationIcon,
                },
                translates: customTranslates,
                language: "no",
            };
        }}
    />,
    document.getElementById("root")
);

serviceWorker.unregister();
